/**
 * @prettier
 */

import styled from 'styled-components'
import { UILink } from 'talkable-ui-kit'
import { useState } from 'react'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  z-index: 100;
  background-color: #1c2940e6;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AcceptButton = styled.button`
  padding: 0.5rem 2.5rem;
  background: #fc501e;
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  border: 0;
  transition: background 0.2s ease-in-out;
  &:hover {
    background: #fe8d10;
  }
`
const TermsText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
`

export const TermsAndConditionsModal: React.FC = ({
  accepted_term_and_conditions,
  account_id,
}) => {
  const [showModal, setShowModal] = useState(true)

  if (!accepted_term_and_conditions && showModal && account_id) {
    const hideTerms = async (): Promise<void> => {
      try {
        await $.ajax({
          type: 'PUT',
          url: Routes.internal_account_path(account_id),
          data: {
            account: {
              accepted_term_and_conditions: true,
            },
          },
          xhrFields: { withCredentials: true },
        })
        setShowModal(false)
      } catch (xhr) {
        notifyDevelopers({
          name: `Unable to patch account accepted_term_and_conditions`,
          message: 'Ajax failure',
          xhr,
        })
        window.location.reload()
      }
    }

    return (
      <Wrapper data-testid="ac-terms-modal">
        <TermsText>
          Before you proceed, we require your acknowledgment and acceptance of our{' '}
          <UILink target="_blank" href="https://www.talkable.com/terms-conditions">
            Terms & Conditions.
          </UILink>{' '}
          By clicking &quot;Accept,&quot; you agree to be bound by the Terms & Conditions
          of Talkable. <br /> Your access to and use of our services are conditioned on
          your acceptance of and compliance with these Terms. These Terms apply to all
          visitors, users, and others who access or use the Service.
        </TermsText>
        <AcceptButton onClick={hideTerms}>Accept</AcceptButton>
      </Wrapper>
    )
  }

  return null
}
