/**
 * @prettier
 */
import { render, unmountComponentAtNode } from 'react-dom'
import get from 'lodash/get'

const ReactUtils = {
  getProps(node) {
    const data = get(node, 'dataset.props', '{}')

    return JSON.parse(data)
  },
  findComponentNode(name) {
    return document.querySelector(`[data-component="${name}"]`)
  },
  renderComponent(node, component) {
    /* eslint-disable no-console*/
    if (!component) return console.error('No component was passed to renderComponent()')
    /* eslint-enable no-console*/
    render(React.createElement(component, this.getProps(node)), node)
  },
  render(component, nodeName) {
    const node = this.findComponentNode(nodeName)

    /* eslint-disable no-console*/
    if (!node) {
      return console.error(`No element found with data-component='${nodeName}' attribute`)
    }
    /* eslint-enable no-console*/
    this.renderComponent(node, component)
  },
  unmount(nodeName) {
    const node = this.findComponentNode(nodeName)

    /* eslint-disable no-console*/
    if (!node) {
      return console.error(`No element found with data-component='${nodeName}' attribute`)
    }
    /* eslint-enable no-console*/
    unmountComponentAtNode(node)
  },
}

export default ReactUtils
